import ContentfulImage from './ContentfulImage';
import Video from './Video';

type MediaProps = {
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
};

type MediaVideoProps = MediaProps & {
  /**
   * The hashed id of the video
   */
  hashedId: string;
  /**
   * Not required for video.
   */
  src?: never;
  /**
   * Not required for video.
   */
  alt?: never;
  /**
   *  Not required for video.
   */
  height?: never;
  /**
   *  Not required for video.
   */
  width?: never;
};

type MediaImageProps = MediaProps & {
  /**
   * Not required for image
   */
  hashedId?: never;
  /**
   * URL of image
   */
  src: string;
  /**
   * Alt text for image
   */
  alt: string;
  /**
   *  Height of image
   */
  height: number;
  /**
   *  Width of image
   */
  width: number;
};

/** We want to enforce the use of props from either MediaVideoProps or
 * MediaImageProps, so we use the never type in each interface to to signify
 * that props from both interfaces should never be used together.
 */
export type MediaContentProps = MediaImageProps | MediaVideoProps;

const Media = ({
  alt,
  hashedId,
  height,
  width,
  src,
  className,
}: MediaContentProps) => {
  return hashedId ? (
    <Video className={className} hashedId={hashedId} />
  ) : (
    <ContentfulImage
      alt={alt}
      className={className}
      height={height}
      src={src}
      width={width}
    />
  );
};

export default Media;
