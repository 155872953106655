'use client';

import type { ReactNode } from 'react';
import { styled, css } from 'styled-components';
import Link from 'next/link';
import cs from 'classnames';
import { trackButton } from '@lib/tracking';
import type { CardColor, CtaProps } from '@types';
import ContentfulImage from './ContentfulImage';

// Note: for each box-shadow, we mix the foreground color of the card with
// the background color of the page
// TODO: I tried abstracting out into a utility function, but couldn't get the
// ES6 template syntax + styled theme syntax quite right, so have left that as
// a future refactor
const hoverShadowShape = '0 18px 24px 10px';
const activeShadowShape = '0 9px 12px 10px';

type ImageCardProps = {
  /**
   * The image for the card
   */
  imgSrc: string;
  /**
   * Alt text for the image
   */
  alt: string;
  /**
   * Heading for the card
   */
  heading: string;
  /**
   * Body text for the card
   * Can accept either a string, or a Fragment with inline-level elements only
   */
  body: ReactNode;
  /**
   * The color scheme of the card
   */
  colorScheme?: Exclude<CardColor, 'darkBlue'>; // Grabbing all card colors except darkBlue, which this component doesn't use!
  /**
   * Optional cta for the card
   */
  ctaBtn?: CtaProps;
};

const ImageCardParagraph = styled.p`
  margin: 0;

  && a {
    color: inherit;
  }
`;

const ImageCardImage = styled(ContentfulImage)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: auto;
  width: 100%;

  ${({ theme }) => theme.mq.minWidth.medium} {
    border-radius: ${({ theme }) => theme.radius.std};
    margin: ${({ theme }) => theme.spacing[2]};
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: unset;
  }
`;

const ImageCardBody = styled.div`
  border-bottom-left-radius: ${({ theme }) => theme.radius.std};
  border-bottom-right-radius: ${({ theme }) => theme.radius.std};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[2]};

  ${({ theme }) => theme.mq.minWidth.medium} {
    align-self: center;
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    align-self: unset;
  }
`;

const ImageCardHeading = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing[1.5]};
`;

const ImageCardButton = styled.div`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radius.btn};
  margin: ${({ theme }) => theme.spacing[1.5]} 0 0;
  padding: ${({ theme }) => `${theme.spacing[0.75]} ${theme.spacing[1.5]}`};
  width: fit-content;

  &::after {
    content: '';
    transition: all 0.25s linear;
  }
`;

const sharedImageCardWrapperStyles = css`
  border-radius: ${({ theme }) => theme.radius.std};
  display: flex;
  flex-direction: column;

  &.blue {
    background-color: ${({ theme }) => theme.colors.blue200};
    color: ${({ theme }) => theme.colors.blue800};
  }

  &.white {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue800};
  }

  &.green {
    background-color: ${({ theme }) => theme.colors.green300};
    color: ${({ theme }) => theme.colors.green800};
  }

  &.pink {
    background-color: ${({ theme }) => theme.colors.pink300};
    color: ${({ theme }) => theme.colors.pink800};
  }

  &.orange {
    background-color: ${({ theme }) => theme.colors.orange200};
    color: ${({ theme }) => theme.colors.orange800};
  }

  &.purple {
    background-color: ${({ theme }) => theme.colors.purple300};
    color: ${({ theme }) => theme.colors.purple800};
  }

  &.yellow {
    background-color: ${({ theme }) => theme.colors.yellow200};
    color: ${({ theme }) => theme.colors.yellow800};
  }

  ${ImageCardParagraph} {
    font-size: ${({ theme }) => theme.typography.fontSize.bodyMd};
    font-weight: ${({ theme }) => theme.typography.fontWeight.body};
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    align-items: center;
    ${({ theme }) => theme.grid.columns.medium};
    column-gap: ${({ theme }) => theme.spacing[2]};
    display: grid;

    > * {
      grid-column: auto / span 3;
    }
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    display: flex;
    flex-direction: column;
    ${ImageCardParagraph} {
      font-size: ${({ theme }) => theme.typography.fontSize.desktop.bodyMd};
    }
  }
`;

const ImageCardLink = styled(Link)`
  ${sharedImageCardWrapperStyles}

  &.white {
    &:visited {
      color: ${({ theme }) => theme.colors.blue800};
    }

    &:hover {
      box-shadow: ${hoverShadowShape}
        color-mix(
          in srgb,
          ${({ theme }) => theme.colors.blue800},
          var(--global-bg-color) 85%
        );
      color: ${({ theme }) => theme.colors.blue800};
    }

    &:active {
      box-shadow: ${activeShadowShape}
        color-mix(
          in srgb,
          ${({ theme }) => theme.colors.blue800},
          var(--global-bg-color) 85%
        );
      color: ${({ theme }) => theme.colors.blue800};
    }
    & ${ImageCardButton} {
      border: 3px solid ${({ theme }) => theme.colors.blue800};
      color: ${({ theme }) => theme.colors.blue800};
    }
    &:hover ${ImageCardButton} {
      background-color: ${({ theme }) => theme.colors.blue800};
    }
  }

  &:hover.blue {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.blue800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.blue800};
  }

  &:active.blue {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.blue800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.blue800};
  }

  &:visited.blue {
    color: ${({ theme }) => theme.colors.blue800};
  }

  &.blue ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.blue800};
    color: ${({ theme }) => theme.colors.blue800};
  }

  &:hover.blue ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.blue800};
  }

  &:hover.green {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.green800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.green800};
  }

  &:active.green {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.green800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.green800};
  }

  &:visited.green {
    color: ${({ theme }) => theme.colors.green800};
  }

  &.green ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.green800};
    color: ${({ theme }) => theme.colors.green800};
  }

  &:hover.green ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.green800};
  }

  &:hover.pink {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.pink800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.pink800};
  }

  &:active.pink {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.pink800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.pink800};
  }

  &:visited.pink {
    color: ${({ theme }) => theme.colors.pink800};
  }

  &.pink ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.pink800};
    color: ${({ theme }) => theme.colors.pink800};
  }

  &:hover.pink ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.pink800};
  }

  &:hover.orange {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.orange800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.orange800};
  }

  &:active.orange {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.orange800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.orange800};
  }

  &:visited.orange {
    color: ${({ theme }) => theme.colors.orange800};
  }

  &.orange ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.orange800};
    color: ${({ theme }) => theme.colors.orange800};
  }

  &:hover.orange ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.orange800};
  }

  &:hover.purple {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.purple800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.purple800};
  }

  &:active.purple {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.purple800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.purple800};
  }

  &:visited.purple {
    color: ${({ theme }) => theme.colors.purple800};
  }

  &.purple ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.purple800};
    color: ${({ theme }) => theme.colors.purple800};
  }

  &:hover.purple ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.purple800};
  }

  &:hover.yellow {
    box-shadow: ${hoverShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.yellow800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.yellow800};
  }

  &:active.yellow {
    box-shadow: ${activeShadowShape}
      color-mix(
        in srgb,
        ${({ theme }) => theme.colors.yellow800},
        var(--global-bg-color) 85%
      );
    color: ${({ theme }) => theme.colors.yellow800};
  }

  &:visited.yellow {
    color: ${({ theme }) => theme.colors.yellow800};
  }

  &.yellow ${ImageCardButton} {
    border: 3px solid ${({ theme }) => theme.colors.yellow800};
    color: ${({ theme }) => theme.colors.yellow800};
  }

  &:hover.yellow ${ImageCardButton} {
    background-color: ${({ theme }) => theme.colors.yellow800};
  }

  &:hover ${ImageCardButton} {
    color: white;

    &::after {
      content: ' →';
    }
  }
`;

const ImageCardWrapper = styled.div`
  ${sharedImageCardWrapperStyles}
`;

const ImageCard = ({
  alt,
  body,
  colorScheme = 'blue',
  heading,
  imgSrc,
  ctaBtn = null,
}: ImageCardProps) => {
  const handleClick = () => {
    trackButton(ctaBtn.trackingLabel, ctaBtn.trackingProperty);
  };

  return ctaBtn ? (
    <ImageCardLink
      className={cs(colorScheme)}
      href={ctaBtn.href}
      onClick={handleClick}
    >
      <ImageCardImage alt={alt} height={320} src={imgSrc} width={416} />
      <ImageCardBody>
        <ImageCardHeading>{heading}</ImageCardHeading>
        <ImageCardParagraph>{body}</ImageCardParagraph>
        <ImageCardButton>{ctaBtn.text}</ImageCardButton>
      </ImageCardBody>
    </ImageCardLink>
  ) : (
    <ImageCardWrapper className={cs(colorScheme)}>
      <ImageCardImage alt={alt} height={320} src={imgSrc} width={416} />
      <ImageCardBody>
        <ImageCardHeading>{heading}</ImageCardHeading>
        <ImageCardParagraph>{body}</ImageCardParagraph>
      </ImageCardBody>
    </ImageCardWrapper>
  );
};

export default ImageCard;
