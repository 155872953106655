'use client';

import type { ReactNode } from 'react';
import { styled } from 'styled-components';
import cs from 'classnames';
import TextLink from '../components/TextLink';

type ImageCardColumnsProps = {
  /**
   * Headline for the introductory content
   */
  headline?: string;
  /**
   * Descriptive paragraph for the introductory content
   */
  description?: string;
  /**
   * Optional link included for the introductory content
   */
  href?: string;
  /**
   * Optional link text for the introductory content
   */
  ctaText?: string;
  /*
   * Either three or four ImageCard components as children
   */
  children:
    | [ReactNode, ReactNode, ReactNode, ReactNode]
    | [ReactNode, ReactNode, ReactNode];
};

const ImageCardsContainer = styled.div`
  padding-block: ${({ theme }) => theme.spacing[3]};
  text-align: center;

  .ImageCards {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-block-start: ${({ theme }) => theme.spacing[1]};

    &.isFlush {
      margin-block-start: 0;
    }

    & > * {
      margin-block-start: ${({ theme }) => theme.spacing[2]};
    }
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    .ImageCards {
      display: grid;
      align-items: start;
      margin-block-start: ${({ theme }) => theme.spacing[3]};
      ${({ theme }) => theme.grid.columns.medium}

      & > * {
        grid-column: auto / span 6;
        margin-block-start: ${({ theme }) => theme.spacing[3]};
      }
    }
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    padding-block: ${({ theme }) => theme.spacing[4]};

    .ImageCards {
      display: grid;
      align-items: start;
      ${({ theme }) => theme.grid.columns.large}

      & > * {
        grid-column: auto / span 4;
        margin-block-start: 0;
      }
    }

    &.four-up .ImageCards > * {
      grid-column: auto / span 3;
    }
  }
`;

/**
 * Thematically grouped image cards, with introductory context.
 */
const ImageCardColumns = ({
  headline,
  description,
  href,
  ctaText = 'Learn More',
  children,
}: ImageCardColumnsProps) => {
  const variant = children.length === 3 ? 'three-up' : 'four-up';
  const hasContextualContent = headline || description || href;

  return (
    <ImageCardsContainer className={cs(variant)}>
      {headline ? <h2>{headline}</h2> : null}
      {description ? <p>{description}</p> : null}
      {href ? <TextLink href={href}>{ctaText}</TextLink> : null}
      <div className={cs('ImageCards', !hasContextualContent && 'isFlush')}>
        {children}
      </div>
    </ImageCardsContainer>
  );
};

export default ImageCardColumns;
